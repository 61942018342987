import * as React from 'react';

function Icon(props) {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#tooltip-icon)">
        <circle cx="9.643" cy="9.643" r="7.071" fill="#000" />
        <path
          d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0Zm1.548 13.642a.647.647 0 0 1-.139.13 4.336 4.336 0 0 1-2.476.808c-.814 0-1.26-.567-1.08-1.36.227-.95.462-1.896.693-2.845a.686.686 0 0 0-.013-.522.58.58 0 0 0-.3-.241 1.148 1.148 0 0 0-.213-.038c-.087-.02-.214-.034-.277-.129l-.02-.03A.292.292 0 0 1 6.807 9l.164-.11.244-.134h-.001a5.224 5.224 0 0 1 1.412-.477c.199-.035.4-.058.603-.067.163 0 .326.017.485.053.585.14.879.66.72 1.294-.225.957-.464 1.911-.698 2.86-.112.454 0 .652.457.774.068.018.139.031.209.047.236.06.292.208.146.401Zm-.769-7.15A1.536 1.536 0 0 1 8.71 3.86a1.537 1.537 0 1 1 1.07 2.634Z"
          fill="#B2FF33"
        />
      </g>
      <defs>
        <clipPath id="tooltip-icon">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

Icon.propTypes = {};

Icon.defaultProps = {};

export default Icon;
