export const isBrowser = typeof window !== 'undefined';

export const getSearch = () => {
  if (isBrowser && window?.location?.search) {
    return window.location.search;
  }
  return '';
};

export const sendGTM = (action, data = {}) => {
  const gtmObj = {
    event: 'microflex_room_design_quiz',
    eventMetaData: {
      action,
      ...data,
    },
  };
  if (isBrowser && window.dataLayer) {
    window.dataLayer.push(gtmObj);
  } else {
    console.info(`GTM EVENT: `, gtmObj);
  }
};

function getParams() {
  return new URLSearchParams(window.location.search);
}

function getParam(key) {
  const params = new URLSearchParams(window.location.search);
  return params?.get(key);
}

export const gtmQuizStart = () => {
  sendGTM('quiz start');
};

export const gtmCopyAndShare = () => {
  sendGTM('copy and share');
};

export const gtmSendToShureExpert = () => {
  sendGTM('send to a shure expert');
};

export const gtmGenerateSolution = () => {
  sendGTM('generate audio solution');
};

export const gtmVideoPlatform = () => {
  sendGTM('roomdesigner-video_platform', {
    user_selection: getParam('videoConferencing'),
  });
};

export const gtmSoundReinforcement = () => {
  sendGTM('roomdesigner-sound_reinforcement', {
    user_selection: getParam('soundReinforcement'),
  });
};

export const gtmRoomSize = () => {
  const params = getParams();
  sendGTM('roomdesigner-room_size', {
    size_string: params?.get('roomSize'),
    dimensions: `${params.get('length')} x ${params.get(
      'width'
    )} x ${params.get('height')}`,
    unit: params.get('unit'),
  });
};

export const gtmSeating = () => {
  sendGTM('roomdesigner-seating_layout', {
    user_selection: getParam('seating'),
  });
};

export const gtmMicPlacement = () => {
  sendGTM('roomdesigner-mic_placement', {
    user_selection: getParam('micPlacement'),
  });
};

export const gtmApplications = () => {
  sendGTM('roomdesigner-applications', {
    user_selection: getParam('applications'),
  });
};

export const gtmCertifiedSolutions = () => {
  sendGTM('roomdesigner-certified-solutions', {
    comments: getParam('certifiedSolutions'),
  });
};

export const replaceLocalesInUrls = (products, locale) => {
  return products.map(function (product) {
    product.url = product.url.replace('en-US', locale);
    return product;
  });
};
