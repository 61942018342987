import styled from 'styled-components';
import PropTypes from 'prop-types';

const Text = styled.p`
  ${({ center }) => center && `text-align: center;`}
  ${({ size, theme }) => (size ? theme.sizes[size] : theme.sizes.p)}
  ${({ color, theme }) => color && `color: ${theme.colors[color]};`}
  ${({ font, theme }) => font && theme.fonts[font]}
`;

Text.propTypes = {
  center: PropTypes.bool,
  color: PropTypes.oneOf(['green', 'black']),
  size: PropTypes.oneOf([
    'f1',
    'f2',
    'f3',
    'f4',
    'f5',
    'p',
    'smallP',
    'smallestP',
  ]),
};

Text.defaultProps = {
  center: false,
};

export default Text;
