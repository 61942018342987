import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from 'components/Text';
import Tooltip from 'components/Tooltip';

import { media } from 'styles/theme';

const Container = styled.div`
  margin-top: 26px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  ${media.ns} {
    margin-top: 69px;
  }
`;

const Instruction = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
`;

const StyledSubTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 14px;
  margin-bottom: 8px;
  text-transform: uppercase;
  ${media.ns} {
    font-size: 15px;
    margin-bottom: 4px;
  }
`;

const StyledTitle = styled(Text)`
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ $maxWidth }) => $maxWidth || 893}px;
  text-align: center;
  ${({ $small, theme }) => ($small ? theme.sizes.f3 : theme.sizes.f2)}
  ${({ $small }) => 'line-height: 42px;'}
  ${({ theme }) => theme.fonts.dinRegular}
`;

const NoWrap = styled.span``;

function Title({
  instruction,
  maxWidth,
  small,
  subTitle,
  title,
  tooltip,
  ...rest
}) {
  function buildTitle() {
    if (tooltip) {
      const arr = title.split(' ');
      const first = arr.slice(0, arr.length - 1) || [];
      return (
        <span id="test-span">
          {first.join(' ')}{' '}
          <NoWrap id="last-word">
            {arr[arr.length - 1]}{' '}
            <Tooltip id="title-tooltip">{tooltip}</Tooltip>
          </NoWrap>
        </span>
      );
    }
    return title;
  }
  return (
    <Container {...rest}>
      <StyledSubTitle as="p" size="f5">
        {subTitle}
      </StyledSubTitle>
      <StyledTitle as="h1" $maxWidth={maxWidth} $small={small}>
        {buildTitle()}
      </StyledTitle>
      {instruction && <Instruction>{instruction}</Instruction>}
    </Container>
  );
}

Title.propTypes = {
  instruction: PropTypes.any,
  maxWidth: PropTypes.number,
  small: PropTypes.bool,
  subTitle: PropTypes.any,
  title: PropTypes.any.isRequired,
};

Title.defaultProps = {
  instruction: null,
  maxWidth: null,
  small: false,
  subTitle: null,
};

export default Title;
