import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/theme';

import ReactTooltip from 'react-tooltip';
import Icon from './Icon';

import { colors } from 'styles/theme';

const ChildrenWrapper = styled.p`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  max-width: 200px;
  margin: 0;
  text-align: center;
  ${({ theme }) => theme.sizes.smallestP}
`;

const IconWrapper = styled.a`
  display: inline-flex;
  transform: translateY(-10px);
  ${media.ns} {
    transform: translateY(-16px);
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  &&& {
    border-radius: 0;
    padding: 13px 14px;
    white-space: break-spaces;
  }
`;

function Tooltip({ children, id, ...rest }) {
  const tooltipProps = {
    backgroundColor: colors.black,
    place: 'top',
    type: 'dark',
    effect: 'solid',
  };

  return (
    <>
      {/* eslint-disable-next-line  */}
      <IconWrapper data-tip data-for={id}>
        <Icon height={18} />
      </IconWrapper>
      <StyledTooltip id={id} {...tooltipProps} {...rest}>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </StyledTooltip>
    </>
  );
}

Tooltip.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {};

export default Tooltip;
